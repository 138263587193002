@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100vh;
  overflow-y: hidden;
}

@media screen and (max-height: 900px) {
  #root {
    height: 100%;
    overflow-y: scroll !important;
  }
}

*,
*::before,
*::after {
  font-family: Roboto, sans-serif;
}
.leaflet-popup-tip-container {
  display: none;
}
.recharts-tooltip-wrapper {
  outline: none;
}
.recharts-legend-item,
.legend-item-0 {
  padding-right: 20px !important;
}
.flipHorizontal {
  transform: scaleX(-1);
}
